<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      :immediately="true"
      url="elevator/maintain-order/page">
      <el-button
        v-if="$auth('保养间隔导出')"
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column
        prop="realEstateName"
        label="楼盘"
        width="180">
      </el-table-column>
      <el-table-column
        prop="buildingName"
        label="楼宇"
        width="180">
      </el-table-column>
      <el-table-column
        prop="elevatorName"
        label="电梯名称"
        width="180">
      </el-table-column>
      <el-table-column
        prop="regCode"
        label="注册代码">
      </el-table-column>
      <el-table-column prop="maintainType" label="保养类型">
      </el-table-column>
      <el-table-column
        prop="dateInterval"
        label="时间间隔">
        <template slot="header" slot-scope="">
          时间间隔 <el-button type="text" icon="el-icon-sort" @click="sort('dateInterval')"></el-button>
        </template>

        <template slot-scope="scope">
          <span :style="{color:scope.row.dateInterval>90?'red':'black' }">{{scope.row.dateInterval}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="totalDoorOpeningCount"
        label="开关门次数">
      </el-table-column>
      <el-table-column
        prop="totalRunningCount"
        label="运行次数">
      </el-table-column>
      <el-table-column
        prop="totalRunningTime"
        label="运行时间">
      </el-table-column>
    </vm-table>
  </div>
</template>

<script>
  import {export2Excel} from "@/util";
  import moment from "moment";

  export default {
    name: "ServiceInterval",
    data() {
      return {
        tableData: [],
        filter: {

          arr:[{
            orderColumnName:"dateInterval",
            asc:0,
          }],
          orderColumnNames:"dateInterval|0",
        },
      };
    },
    mounted() {
      // this.getData();
    },
    methods:{
      sort(name) {
        if (this.filter.arr.some(item=>item.orderColumnName===name)) {
          const arr = this.filter.arr.map(item=>{
            console.log(item.orderColumnName,name);
            if (item.orderColumnName===name) {
              item.asc=item.asc?0:1;
            }
            return item;
          });
          this.filter.arr=arr;
        }else {
          this.filter.arr.push({
            orderColumnName:name,
            asc:0,
          });
        }
        let str = "";
        this.filter.arr.forEach(item=>{
          str+=item.orderColumnName+"|"+item.asc+"&";
        });
        this.filter.orderColumnNames = str;
        this.getList();
      },
      exportExcelData() {
        const title={
          realEstateName:"楼盘",
          buildingName:"楼宇",
          elevatorName:"电梯名称",
          regCode:"注册代码",
          dateInterval:"保养间隔",
          maintainType:"保养类型",
          totalDoorOpeningCount:"开关门次数",
          totalRunningCount:"运行次数",
          totalRunningTime:"运行时间",
        };
        this.$http.get("elevator/maintain-order/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`保养间隔统计-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      getData() {
        this.$http.get("elevator/maintain-order/list").then(res=>{
          this.tableData= res;
        });
      },
    },
  };
</script>

<style scoped>

</style>
